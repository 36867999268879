
import LoadingMask from "@/components/common/LoadingMask.vue";
import PromoPT15USDPricingColumn from "@/components/landingPages/quickPurchase/promotions/proteam15usd/PromoPT15USDPricingColumn.vue";
import pageRenderer, {
  RenderPageGridConfig,
} from "@/components/views/subscriptions/modals/buyProcess/components/pageRenderer";
import { LoadingMaskProps } from "@/types/loadingMask";
import { PlanModel, QuickPurchaseData } from "@/types/localtypes";
import { JPayPurchaseResponse, JPCInstance, SubscriptionTypes } from "@jfrog-ba/myjfrog-common";
import { Component, Inject, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component({
  name: "PromoPT15USDBuyNow",
  components: { LoadingMask, PromoPT15USDPricingColumn },
})
export default class PromoPT15USDBuyNow extends Vue {
  @Inject() readonly globalBus!: Vue;
  @Getter("quickPurchaseData", { namespace: "quickPurchase" })
  quickPurchaseData!: QuickPurchaseData;
  paymentObjectWrapperId = "payment-object-wrapper-buy-process";
  jpcLoaded = false;
  paymentScript = "";
  buyFormDisplayed = false;
  isFirstStep = true;
  isLoading = true;
  forceDisableSubmitButton = false;

  get stepTitle() {
    return this.isFirstStep ? "Company details" : "Payment";
  }

  get planModel(): PlanModel {
    return this.$jfPricing.createPlan(
      { canPurchasePipelines: false, isProcessable: true, core: "DEVOPS" },
      this.proTeamSubscriptionType,
      [],
    );
  }

  get currentRatePlanId() {
    return (this.proTeamSubscriptionType && this.proTeamSubscriptionType.ratePlanId) || "unknown";
  }

  get promotionPaymentType() {
    return (this.proTeamSubscriptionType && this.proTeamSubscriptionType.paymentType) || undefined;
  }

  get proTeamSubscriptionType(): SubscriptionTypes | undefined {
    // /* LOG */ console.log(`this.quickPurchaseData`, this.quickPurchaseData);
    return (
      this.quickPurchaseData.subscriptionTypes &&
      this.quickPurchaseData.subscriptionTypes.find(value => value.paymentType === "PRO_TEAM_MONTHLY")
    );
  }
  get dataGtmPage() {
    return `pro-team-offer-buy-now`;
  }

  get nextStepBtnLabel() {
    return this.isFirstStep ? "Next" : "Upgrade my plan";
  }

  get nextGtmEvent() {
    const buttonLabel = this.isFirstStep ? "next" : "upgrade";
    return `${this.dataGtmPage}|${buttonLabel}-button`;
  }
  get loadingMaskProps(): LoadingMaskProps {
    return {
      loading: this.isLoading,
      zIndex: 10000, //to be on top of Zuora card iframe
      autoHeight: true,
    };
  }

  async mounted() {
    // @ts-ignore
    if (window.JPC) {
      this.jpcLoaded = true;
    } else {
      try {
        this.paymentScript = await this.$jfPayments.fetchPaymentsScripts();
        if (this.paymentScript) {
          eval(this.paymentScript);
          this.jpcLoaded = true;
        }
      } catch (e) {
        this.$log.error(e);
        this.notifyError(this.$jfMessages.jpay_loading_failed);
        this.toErrorView();
      }
    }

    if (this.jpcLoaded) {
      this.loadPlans();
      this.loadJPCBuyForm();
    }
  }

  toCongratulations() {
    this.$router.push({ name: "pro-team-offer-congratulations" });
  }

  loadPlans() {
    if (!this.proTeamSubscriptionType) {
      this.$log.error("PaymentType not found");
      this.notifyError(this.$jfMessages.subscriptions_upgrade_error_unknown);
      this.toErrorView();
    }
  }

  loadJPCBuyForm() {
    // @ts-ignore
    const jpc: JPCInstance = new JPC(
      this.getJPCPrepopulate(),
      this.getJPCMessages(),
      this.getJPCOptions(),
      this.loadJPCCallbacks(),
    );
    setTimeout(() => {
      jpc.loadBuyCustomerDetailsForm();
    }, 2000);
  }

  getJPCOptions(): JPCInstance["options"] {
    return {
      divJpcFormId: this.paymentObjectWrapperId,
      placeHoldersActive: false,
      optionalHeaders: {
        "x-quick-purchase-promotion-token": this.quickPurchaseData.token,
        "x-quick-purchase-promotion-servername": this.quickPurchaseData.serverName,
      },
      creditCardIframeWidth: null,
      creditCardIframeHeight: 300,
      extraHostedPageName: "proTeamPromotion",
    };
  }

  getJPCPrepopulate(): JPCInstance["prepopulate"] {
    return {
      firstName: " ",
      lastName: " ",
      email: "dummy@jfrog.com",
      confirmEmail: "dummy@jfrog.com",
      phone: "",
      country: "",
      city: "",
      state: "",
      address: "",
      zip: "",
      vatId: null,
      company: "",
      billingFirstName: "",
      billingLastName: "",
      billingEmail: "",
      billingConfirmEmail: "",
      billingPhone: "",
      billingAddress: "",
      billingZip: "",
      billingState: null,
      billingCountry: "",
      billingCity: "",
      billingCompany: "",
      ratePlanId: this.currentRatePlanId,
      additionalRatePlanIds: [],
    };
  }

  getJPCMessages(): JPCInstance["messages"] {
    return {
      label_totalBeforeTax: "Total",
      label_tax: "Estimated Tax To Be Collected",
      label_amount: "Total",
    };
  }

  get nextStepBtnDisabled() {
    return this.isLoading || this.forceDisableSubmitButton;
  }

  get jpcLogErrorPrefix() {
    return `jpc-callback-quick-purchase-promotion-proteam-15usd [${this.quickPurchaseData.serverName}] : `;
  }

  loadJPCCallbacks(): JPCInstance["callbacks"] {
    return {
      buyCustomerDetailsForm_hideOverlayFor3DS: () => {
        // console.log("CB buyCustomerDetailsForm_hideOverlayFor3DS");
        this.forceDisableSubmitButton = true;
        setTimeout(() => {
          this.setLoading(false);
        }, 3000);
      },
      buyCustomerDetailsForm_render(html: HTMLDivElement) {
        // console.log("CB buyCustomerDetailsForm_render");
        const renderPageGridConfig: RenderPageGridConfig = {
          nbColumns: 2,
          idFragmentsToHide: ["jpc-details-billing", "jpc-details-sameInfo"],
          hideTitle: true,
          gridOrderMap: {
            "jpc-details-company": 0,
            "jpc-details-country": 1,
            "jpc-details-state": 2,
            "jpc-details-city": 3,
            "jpc-details-address": 4,
            "jpc-details-zip": 5,
            "jpc-details-vatId": 6,
          },
        };
        return pageRenderer.renderPage(html, renderPageGridConfig);
      },
      buyCustomerDetailsForm_renderPriceElement: (price: string) => {
        // console.log("CB buyCustomerDetailsForm_renderPriceElement", price);
        return price.replace("$", "<span class='currency'>$</span>");
      },
      buyCustomerDetailsForm_transformRatePlanId: (ratePlanId: string) => {
        // console.log("CB buyCustomerDetailsForm_transformRatePlanId");
        return this.currentRatePlanId;
      },
      buyCustomerDetailsForm_submitCheckoutSuccess: (response: JPayPurchaseResponse) => {
        // console.log("CB buyCustomerDetailsForm_submitCheckoutSuccess");
        this.handlePurchaseSuccess(response);
        this.forceDisableSubmitButton = false;
      },
      buyCustomerDetailsForm_loadSuccess: () => {
        // console.log("CB buyCustomerDetailsForm_loadSuccess");
        this.buyFormDisplayed = true;
        pageRenderer.renderSelect({
          [pageRenderer.country]: "Select country",
          [pageRenderer.state]: "Select state",
        });
        this.createEvents();
        this.hideStateIfNotNeeded();
        this.setLoading(false);
      },
      buyCustomerDetailsForm_loadError: (status: number, errorMessage: string) => {
        // console.log("CB buyCustomerDetailsForm_loadError");
        this.buyFormDisplayed = false;
        const jpcErrorMessage = `${this.jpcLogErrorPrefix}error : ${status} => ${errorMessage || "no message"}`;
        this.$log.error(jpcErrorMessage);
        this.setLoading(false);
        if (status === 401) {
          this.$jfUsers.handleUnauthorized();
          return;
        }
        this.notifyError(this.$jfMessages.jpay_buy_form_loading_failed);
      },
      buyCustomerDetailsForm_submitError: (status: number, errorMessage: string, exceptionClass: string) => {
        // console.log("CB buyCustomerDetailsForm_submitError");
        const jpcErrorMessage = `${this.jpcLogErrorPrefix}Error : ${status} => ${errorMessage || "no message"}${
          exceptionClass ? ` (exception : ${exceptionClass})` : ""
        }`;

        this.$log.error(jpcErrorMessage);
        this.setLoading(false);

        if (status === 401) {
          this.$jfUsers.handleUnauthorized();
          return;
        }

        if (exceptionClass && exceptionClass.includes("TaxValidationErrorException")) {
          // we don't show error notification because JPay will display UI errors into the form.
          return;
        }

        this.notifyError(this.$jfMessages.jpay_buy_form_submit_failed);
      },
      buyCustomerDetailsForm_submitCheckoutError: (message: string) => {
        // console.log("CB buyCustomerDetailsForm_submitCheckoutError");
        this.$log.error(`${this.jpcLogErrorPrefix}${message}`);
        this.setLoading(false);

        this.notifyError(this.$jfMessages.extractPaymentsError(message));
        this.forceDisableSubmitButton = false;
      },
      buyCustomerDetailsForm_beforeSubmitCheckout: () => {
        // console.log("CB buyCustomerDetailsForm_beforeSubmitCheckout");
        this.setLoading(true);
        return true;
      },
      buyCustomerDetailsForm_sameInfosClicked: () => {
        pageRenderer.forceSelect(pageRenderer.countryBilling, "forceSelect");
        pageRenderer.pushOptions(pageRenderer.stateBilling, "options");
        pageRenderer.forceSelect(pageRenderer.stateBilling, "forceSelect");
      },
      buyCustomerDetailsForm_beforeSubmit: () => {
        this.setLoading(true);
        return true;
      },

      buyCustomerDetailsForm_beforeCheckForm: () => {
        return true;
      },

      buyCustomerDetailsForm_beforeChangePage: () => {
        this.isFirstStep = false;
        return true;
      },
      buyCustomerDetailsForm_afterChangePage: () => {
        setTimeout(() => {
          this.setLoading(false);
        }, 1500);
        return true;
      },
      buyCustomerDetailsForm_beforePrevCheckout: () => {
        return true;
      },
      global_selectStateChanged: (select: string) => {},
      global_selectCountryChanged: (select: string) => {},
    };
  }

  setLoading(loading: boolean) {
    this.isLoading = loading;
  }

  get nextBtnClasses() {
    const classes = [];
    if (this.isFirstStep) {
      classes.push("w-30");
    } else {
      classes.push("w-40");
    }
    return classes;
  }

  get jpcFormWrapperClasses() {
    const classes = [];
    if (this.isFirstStep) {
      classes.push("firstStep");
    }
    return classes;
  }

  get paymentObjectWrapperClasses() {
    const classes = [];
    if (this.isFirstStep) {
      classes.push("row");
    } else {
      classes.push("w-100");
    }
    return classes;
  }

  get buyFormWrapperStyle() {
    return {};
    // return {
    //   minHeight: this.minHeight + "px",
    // };
  }

  async handlePurchaseSuccess(jpayPurchaseForm: JPayPurchaseResponse) {
    try {
      if (!this.promotionPaymentType) {
        return;
      }
      await this.$jfSubscriptions.quickPurchaseCloudPromotion({
        marketoCookie: this.$jfMarketo.getCookie(),
        newPaymentType: this.promotionPaymentType,
        purchaseForm: jpayPurchaseForm,
        serverName: this.quickPurchaseData.serverName,
        token: this.quickPurchaseData.token,
      });
      this.toCongratulations();
    } catch (e) {
      this.$log.error(`${this.jpcLogErrorPrefix} on success handler : ${e.message}`);
      this.setLoading(false);
      this.notifyError(this.$jfMessages.subscriptions_upgrade_error_unknown);
    }
  }

  createEvents() {
    const countrySelect = document.querySelector(pageRenderer.country);
    const countryBillingSelect = document.querySelector(pageRenderer.countryBilling);
    const additionalBillingSelect = document.querySelector(pageRenderer.additionalBillingSelect);

    if (countrySelect && countryBillingSelect) {
      countrySelect.addEventListener("change", () => {
        pageRenderer.pushOptions(pageRenderer.state, "options");
        pageRenderer.resetIdError(pageRenderer.country);
        this.hideStateIfNotNeeded();
      });
      countryBillingSelect.addEventListener("change", () =>
        pageRenderer.pushOptions(pageRenderer.stateBilling, "options"),
      );
    }
    if (additionalBillingSelect) {
      (additionalBillingSelect as HTMLSelectElement).disabled = false;
    }
  }

  hideStateIfNotNeeded() {
    const stateContainerId = `${this.paymentObjectWrapperId}jpc-details-state`;
    const stateInput = document.getElementById(`${this.paymentObjectWrapperId}jpc-input-state`) as HTMLSelectElement;
    stateInput && this.toggleState(stateContainerId, !stateInput.disabled);
  }

  toggleState(id: string, display: boolean) {
    const stateDiv = document.getElementById(id) as HTMLDivElement;
    if (!stateDiv) {
      return;
    }
    const classListAction = display ? "remove" : "add";
    stateDiv.classList[classListAction]("jpc-hidden-field");
  }

  handleNextStep() {
    const sameInfoCheckBox = document.getElementById(
      `${this.paymentObjectWrapperId}jpc-checkbox-sameInfo`,
    ) as HTMLInputElement;

    if (!sameInfoCheckBox) {
      return;
    }
    // trick to click the hidden checkbox
    if (sameInfoCheckBox.checked) {
      sameInfoCheckBox.click();
    }
    sameInfoCheckBox.click();

    if (this.isFirstStep) {
      const btnNext = document.getElementById(`${this.paymentObjectWrapperId}jpc-btn-next`);
      btnNext && btnNext.click();
    } else {
      this.$jfNotification.clearAll();
      const btnSubmit = document.getElementById(`${this.paymentObjectWrapperId}jpc-btn-submit`);
      btnSubmit && btnSubmit.click();
    }
  }

  toErrorView() {
    this.$router.push({ path: "error" });
  }

  notifyError(errorMessage: string) {
    this.globalBus.$emit("notifyError", errorMessage);
  }
}


import { PlanContent, PlanModel } from "@/types/localtypes";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "PromoPT15USDPricingColumn",
  components: {},
})
export default class PromoPT15USDPricingColumn extends Vue {
  @Prop() private plan!: PlanModel;

  contentIconClasses(content: PlanContent) {
    const classes: string[] = [];

    if (content.icon) {
      classes.push(content.icon.name);
      if (content.icon.fontSize) {
        classes.push(`fs-${content.icon.fontSize}`);
      }
      if (content.icon.cssClass) {
        classes.push(content.icon.cssClass);
      }
    }
    return classes;
  }

  get disclaimer() {
    return "Valid for 5 months; JFrog's standard fees apply thereafter.";
  }
}
